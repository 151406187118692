/* 
 hamburger base
 */
.hamburger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: $bar-length;
  height: $bar-length + $bar-thick;
  background: none !important;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  z-index: 999;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:focus {
    outline: none;
  }
  /* 
   hamburger ingradient
   繪製另外兩條線
   */
  > .bars {
    width: $bar-length;
    height: $bar-thick;
    background: $bar-colour;
    transition: all 0.3s ease-in-out;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: $bar-length;
      height: $bar-thick;
      background: $bar-colour;
      border-radius: $bar-thick;
      transition: all 0.3s ease-in-out;
    }
    &:before {
      transform: translateY(-$bar-gap);
    }
    &:after {
      transform: translateY($bar-gap);
    }
  }
  &.active > .bars {
    /* 按鈕往左退的距離 */
    // transform: translateX(-$bar-length/3.5);
    background: transparent;
    box-shadow: none;
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
