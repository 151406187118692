@import "config/variables";
@import "config/mixin";
@import "hamburger";
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
  background-color: $bg-colour;
}
body {
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  &.disable-scroll {
    overflow: hidden;
  }
}

input {
  outline-offset: 3px;
}
