/* Config */
$surfaceBlueWeb: #22a2a2;
$deepWaterBlue: #071937;
$westCoastSand: #4c5662;
$baseline: 24px; // must be PX due to calculate purpose
$Archivo: "Archivo", sans-serif;

/* Theme */
$theme-colour: $surfaceBlueWeb;
$theme-black: rgb(24, 24, 24);
$theme-dark: #212121;
$theme-grey: rgb(240, 240, 240);
$theme-font: $Archivo;
$font-spacing: 1px;

$nav-colour: $theme-colour;
$bg-colour: $theme-grey;
$nav-bg: $theme-dark;

/* @media only screen */
$screen-xl: 1440px;
$screen-lr: 1024px;
$screen-md: 768px;
$screen-sm: 640px;
$screen-xs: 480px;

/* hamburger */
$bar-length: 24px;
$bar-thick: 2px;
$bar-gap: 6px;
$bar-colour: $westCoastSand;

/* off canvas */
$off-position: $bar-length + $baseline * 1.667; // hamburger width + spacing
$expanded-width: 240px;
